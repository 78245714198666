import React from "react"
import logo from './logo.png';
import Home from './Home'
import C_T from './components/C_T'
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import './App.css';

import nessentielle from './www.nessentielle.mg_.png'
import polytechnique from './upgrade.polytechnique.mg_.png'
import seismicProject from './sismicProject.png'
import madeko from './madeko.png'
import espaApp1 from './espaApp1.png'
import espaApp2 from './espaApp2.png'
import analysisApp1 from './Accueil.png'
import analysisApp2 from './Acqui1.png'

const Index = (props) => {
  var prev= "/6"
  var next= "/1"
  return(
    <Home
      next={next}
      prev={prev}
    />
  )
}
const C1 = (props) => {
  var prev= "/"
  var next= "/2"
  return(
    <C_T
      number="1"
      next={next}
      prev={prev}
      title="Analysis App"
      description="This application is used to remotely control an electrochemical device that I've also developed. AI is implemented into the application in order to do predictions with the output of the elecrochemical device."
      realisation=" 2021"
      context=" Dissertation"
      role=" Concept and Dev"
      image1={analysisApp1}
      image2={analysisApp2}
      screen="mobile"
    />
  )
}
const C2 = (props) => {
  var prev= "/1"
  var next= "/3"
  return(
    <C_T
      number="2"
      next={next}
      prev={prev}
      title="Sismic Project"
      description="This project was born at the High School Polytechnic of Antananarivo. It aims to provide a set of tools to read and process on sismic signals."
      realisation=" 2021 with RABARISOA Irina Fanasina"
      context=" Dissertation"
      role=" Development"
      image={seismicProject}
      screen="desktop"
    />
  )
}
const C3 = (props) => {
  var prev= "/2"
  var next= "/4"
  return(
    <C_T
      number="3"
      next={next}
      prev={prev}
      title="Ecole Superieure Polytechnique d'Antananarivo"
      description="I'm working on the website of the Ecole Supérieure Politechnique d'Antananarivo."
      realisation=" 2021"
      context=" Showcase Site and Services"
      role=" Development and SEO"
      link="https://polytechnique.mg"
      image={polytechnique}
      screen="desktop"
    />
  )
}
const C4 = (props) => {
  var prev= "/3"
  var next= "/5"
  return(
    <C_T
      number="4"
      next={next}
      prev={prev}
      title="App ESPA"
      description="This is an application that allows you to receive articles, news, photos, etc. published on the website of the Ecole Superieure Polytechnique d'Antananarivo."
      realisation=" 2021 with Techno-C club"
      context=" Educational"
      role=" Concept and Dev"
      image1={espaApp1}
      image2={espaApp2}
      screen="mobile"
    />
  )
}
const C5 = (props) => {
  var prev= "/4"
  var next= "/6"
  return(
    <C_T
      number="5"
      next={next}
      prev={prev}
      title="Madagasikara Ekôlôjia"
      description="Researchers convinced that conservation is one of the main links in the promotion of sustainable development. MADAGASIKARA EKÔLÔJIA was created to be a synaptic type link. Its essential role is thus to translate languages used into languages that are understandable by all."
      realisation=" 2020 with ZW Team"
      context=" Organisation"
      role=" Project Manager"
      link="https://madagasikara-ekolojia.com"
      image={madeko}
      screen="desktop"
    />
  )
}
const C6 = (props) => {
  var prev= "/5"
  var next= "/"
  return(
    <C_T
      number="6"
      next={next}
      prev={prev}
      title="N'essentielle"
      description="N'essentielle is an e-commerce specialized in the sale of baby products and drugstore."
      realisation=" 2020"
      context=" E-commerce"
      role=" Development and SEO"
      link="https://nessentielle.mg"
      image={nessentielle}
      screen="desktop"
    />
  )
}

function App() {

  const vH = window.innerHeight;
  const vW = window.innerWidth;

  const arrow = document.getElementsByClassName("mwindow");

  const animate = (e)=>{
      const x = Math.round(e.clientX - vW/2);
      const y = Math.round(e.clientY - vH/2);
      var tx = (x/(vW/2))*6;
      var ty = (y/(vH/2))*6;
      //console.log({x: tx, y: ty})
      var style = "transform: perspective(2500px) scale(0.8) rotateY("+tx+"deg) rotateX("+(-ty)+"deg); -webkit-transform-origin-z: -300px; transition: all 0.5s cubic-bezier(0, 0, 0.28, 1.03)"
      //console.log(style)
      arrow[0].setAttribute("style", style);
  }
  
  const animateM = (e)=>{
      const x = Math.round(e.clientX - vW/2);
      const y = Math.round(e.clientY - vH/2);
      var tx = (x/(vW/2))*2.5;
      var ty = (y/(vH/2))*2.5;
      //console.log({x: tx, y: ty})
      var style = "transform: perspective(2500px) scale(0.8) rotateY("+tx+"deg) rotateX("+(-ty)+"deg); -webkit-transform-origin-z: -300px; transition: all 0.7s cubic-bezier(0, 0, 0.28, 1.03)"
      //console.log(style)
      arrow[0].setAttribute("style", style);
  }

  const animateReset = ()=>{
      var style = "transform: perspective(2500px) scale(0.8) rotateY(0deg) rotateX(0deg); -webkit-transform-origin-z: -300px; transition: all 0.7s cubic-bezier(0, 0, 0.28, 1.03)"
      setTimeout(()=>{
        arrow[0].setAttribute("style", style);
      }, 700)
  }

  if(/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) === true){
    window.addEventListener("pointerdown", (e) => {
      animateM(e)
    });
    window.addEventListener("touchend", () => {
      animateReset()
    });
  }else{
    window.addEventListener("mousemove", (e) => {
      animate(e)
    });
  }


  console.log("I'm Abioud RABEZANAHARY. Contact me at fenosoa.abi@gmail.com")

  return (
    <div className="mwindow full-screen" style={{transform: "perspective(2500px) scale(0.8)", WebkitTransformOriginZ: -300}}>
      <BrowserRouter>
              <nav className="justify-content-between bg-nav navbar navbar-expand p-3">
                <div>
                  <Link to="/">
                    <img src={logo} alt="" width="40" height="40"/>
                  </Link>
                </div>
                <div>
                  <ul className="nav navbar-nav mr-auto">
                    <li className="nav-item">
                      <Link to="/" className="nav-link text-dark">Home</Link>
                    </li>
                    <li className="nav-item">
                      <Link to="/1" className="nav-link text-dark">Projects</Link>
                    </li>
                  </ul>
                </div>
              </nav>
              <Switch>
                <Route exact path="/" component={Index}/>
                <Route 
                  exact
                  path="/1"
                  component={C1}
                />
                <Route 
                  exact
                  path="/2"
                  component={C2}
                />
                <Route 
                  exact
                  path="/3"
                  component={C3}
                />
                <Route 
                  exact
                  path="/4"
                  component={C4}
                />
                <Route 
                  exact
                  path="/5"
                  component={C5}
                />
                <Route 
                  exact
                  path="/6"
                  component={C6}
                />
              </Switch>
        </BrowserRouter>  
    </div>
  );
}

export default App;
