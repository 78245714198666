import React from 'react';

function Thumbnail(props) {
    if(props.screen === "desktop"){
        return (
            <div className="d-flex">
                <img className="tiles" src={props.image} style={{filter: "contrast(35%) brightness(1.6)", maxHeight: "60vh"}} alt=""/>
            </div>
        )
    }else{
        return (
            <div className="d-flex justify-content-around">
                <img  className="tilesm" src={props.image1} style={{filter: "contrast(35%) brightness(1.6)", maxHeight: "65vh", marginRight: 10}} alt=""/>
                <img  className="tilesm" src={props.image2} style={{filter: "contrast(35%) brightness(1.6)", maxHeight: "65vh"}} alt=""/>
            </div>
        )
    }
}

export default Thumbnail;