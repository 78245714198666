import React from 'react';
import './App.css'
import Fade from 'react-reveal/Fade'
import { Link } from "react-router-dom";

function Projects(props) {
    return(
        <div className="d-flex wrap align-items-center"
        style={{height: "100%", width:"100%"}}>
            <Fade ssrFadeout>
                <div 
                    className="d-flex wrap align-items-center"
                    style={{height: "100%", width:"100%"}}
                >
                    {/* <img src={Programming} style={{height: "35vh", position:"absolute", transform: "translate(50%,-50%)", zIndex: 0, top: "50vh", right: "calc(35vw)"}} alt="" /> */}
                    <div style={{paddingLeft: "10vw", paddingRight: "10vw", paddingBottom: "10rem", zIndex: 5 , height: "100%", width: "100%", display: "flex", flexDirection: "column", flexWrap: "wrap", justifyContent: "space-between"}}>
                        <h4 className="text-dark display-5">Portfolio.</h4>
                        <h1 className="text-dark display-5" style={{alignSelf: "center", borderRight: "black solid 2px", borderBottom: "black solid 2px", paddingRight: 10}}>
                            <span 
                                className="display-1"
                                style={{borderLeft: "black solid 2px", borderTop: "black solid 2px", paddingLeft: 20, paddingRight: 20}}
                            >
                                .
                            </span>
                            <span 
                                className="display-1"
                            >
                                Abioud
                            </span>
                            <br></br>
                            RABEZANAHARY
                        </h1>
                        <div>
                            <Fade bottom>
                            <p className="text-black" style={{borderLeft: "black solid 1px", paddingLeft: 20, }}>
                                Madagascar
                                <br />
                                abioudrabezanahary@gmail.com
                                <br></br>
                                LinkedIn : <a href='https://www.linkedin.com/in/abioud-rabezanahary'>Abioud RABEZANAHARY</a>
                                <br></br>
                                +261 34 27 382 90
                            </p>
                                <p className="text-black">
                                    <span className="hinted rounded" style={{background: "#081600", paddingBottom: 7, paddingTop: 7}}>
                                        <span className="text-overlay" style={{overflowWrap: 'break-word', hyphens: 'auto'}}>
                                            {"Web | Mobile | AI | Chemical Engineering | 3D | Robotics "}
                                        </span>
                                    </span>
                                </p>
                            </Fade>
                        </div>
                    </div>
                </div>
            </Fade>
            <Link to={props.next} style={{position: "absolute", bottom: "1rem", right: "1rem"}}>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", width:65, height:65, backgroundColor:"#0005", borderRadius:32.5}}>
                    <span style={{color: "white"}}>
                        Next
                    </span>
                </div>
            </Link>
            <Link to={props.prev} style={{position: "absolute", bottom: "1rem", left: "1rem"}}>
                <div style={{display: "flex", justifyContent: "center", alignItems: "center", width:65, height:65, backgroundColor:"#0005", borderRadius:32.5}}>
                    <span style={{color: "white"}}>
                        Prev
                    </span>
                </div>
            </Link>
        </div>
    )
}

export default Projects;